<template>
    <div id="facility-search--groups">
        <v-data-table
            :headers="headers"
            :items="groups"
            :value="[selectedGroup]"
            :no-data-text="'No Property Groups have been created.'"
            @click:row="onGroupSelection"
            fixed-header
            disable-pagination
            hide-default-footer
            :height="$vuetify.breakpoint.xs ? '77vh' : '334px'"
            :mobile-breakpoint="320"
        >

            <template v-slot:item.pg_created_by="{ item }">
              {{ item.pg_created_by }}
              <br />
              <span class="hb-text-light">{{ item.created_at | formatDate("MMM DD, YYYY") }}</span>
            </template>

            <template v-slot:item.properties="{ item }">
                    <span>{{ item.properties.length }}</span>
            </template>

            <template v-slot:[`item.occupancy`]="{ item }">
                <occupancy :leases="item.lease_count" :units="item.unit_count" />
            </template>

            <template v-slot:item.property_group_type_id="{ item }">
                <span>{{ getPropertyGroupTypeName(item.property_group_type_id) }}</span>
            </template>

        </v-data-table>
    </div>
</template>
<script>
import Occupancy from './Occupancy.vue';
import { mapActions } from 'vuex';
import { propertyGroupTypeMixin } from '@/mixins/propertyGroupTypeMixin.js';
export default {
    mixins: [propertyGroupTypeMixin],
    data() {
        return {
            headers: [{
                value: "name",
                text: "Group Name",
                sortable: true,
                width: "150"
            },{
                value: "properties",
                text: "# Properties",
                width: "100px",
                sortable: true,
                class: "property_column"
            },{
                value: "occupancy",
                text: "Occupancy",
                width: "90px",
                sortable: true,
                class: "occupancy_column"
            }
            ],
        }
    },
    props: [
        'properties', 
        'propertyGroups', 
        'selectedGroup'
    ],
    components: {
        Occupancy
    },
    computed: {
        groups() {
            this.propertyGroups.forEach(function(pg) {
                pg.occupancy = pg.lease_count / pg.unit_count * 100;
            })
            return this.propertyGroups;
        }
    },
    created() {
        if(!this.$vuetify.breakpoint.xs) {
            let createdByObj = {
                value: "pg_created_by",
                text: "Created By",
                sortable: true,
                width: "130"
            };
            this.headers = [...this.headers, ...[{
                value: "unit_count",
                text: "Spaces",
                sortable: true,
                width: "70",
                class: "space_column"
            },{
                value: "property_group_type_id",
                text: "Group Type",
                sortable: true,
                width: "80",
                class: "group_column"
            }]]
            this.headers.splice(1, 0, createdByObj);
        }
    },
    methods: {
        ...mapActions({
            setSelectedPropertyGroup: 'propertiesStore/setSelectedPropertyGroup'
        }),
        onGroupSelection(group) {
            let group_properties = group.properties.map(p => p.id);
            let selectedProperties = this.properties.filter(p => group_properties.includes(p.id))
            this.setSelectedPropertyGroup(group);
            this.$emit("handleGroupSelection", selectedProperties);
        }
    }
}
</script>
<style lang="scss">
#facility-search--groups {
    table {
        thead {
            height:48px;
            tr {
                th {
                    background: #F5F6F8;
                    border-top: 0.5px solid #E0E3E8;
                    font-weight:normal;
                    color:#474F5A;
                    font-size:14px;
                    padding: 0 10px;
                }
                th:first-of-type{
                    padding-left:27px;
                }
                th:last-of-type{
                    padding-right:0;
                }
            }
        }
    }
    .v-data-table {
        .v-data-table-header {
            background: #eaeaea;
        }
        tbody {
            tr {
                &:hover {
                    background-color:#F9FAFB;
                }
                &.v-data-table__selected {
                    background-color:#E0F5F5;
                }
                td {
                    border-bottom: none;
                    padding: 10px 10px;
                    line-height: 1.5;
                }
                td:first-of-type{
                    padding-left:27px;
                }
                border-bottom: 1px solid #0000001f;
            }
        }
    }
}
table .space_column i.v-icon,
table .group_column i.v-icon {
    position: absolute;
}
@media (max-width: 600px) {
    .occupancy_column i.v-icon,
    .property_column i.v-icon {
        position: absolute;
    }
}
</style>